import React, { useState, useEffect } from 'react';
import { AppBar, Box, Toolbar } from '@mui/material';
import logo from '../assets/logo/alamar.png';
// import heartlogo from '../assets/logo/alamar_pink_heart.png';
import '../App.css';
import axios from 'axios';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://d3278dcv8tp8kw.cloudfront.net/';

// const imageDatas = [{
//     "about_id": 99,
//     "shop_id": 156,
//     "about_image": "5x7+webp/About/about_5x7+(10).webp",
//     "about_image_color": "Red",
//     "shop_image": "5x7+webp/Shop/shop_5x7+(19).webp",
//     "shop_image_color": "Pink"
// },
// {
//     "about_id": 121,
//     "shop_id": 165,
//     "about_image": "5x7+webp/About/about_5x7+(32).webp",
//     "about_image_color": "Green",
//     "shop_image": "5x7+webp/Shop/shop_5x7+(28).webp",
//     "shop_image_color": "Orange"
// },
// {
//     "about_id": 100,
//     "shop_id": 162,
//     "about_image": "5x7+webp/About/about_5x7+(11).webp",
//     "about_image_color": "Red",
//     "shop_image": "5x7+webp/Shop/shop_5x7+(25).webp",
//     "shop_image_color": "Orange"
// },
// {
//     "about_id": 94,
//     "shop_id": 141,
//     "about_image": "5x7+webp/About/about_5x7+(5).webp",
//     "about_image_color": "Blue",
//     "shop_image": "5x7+webp/Shop/shop_5x7+(4).webp",
//     "shop_image_color": "Red"
// },
// {
//     "about_id": 98,
//     "shop_id": 163,
//     "about_image": "5x7+webp/About/about_5x7+(9).webp",
//     "about_image_color": "Red",
//     "shop_image": "5x7+webp/Shop/shop_5x7+(26).webp",
//     "shop_image_color": "Orange"
// },
// {
//     "about_id": 132,
//     "shop_id": 142,
//     "about_image": "5x7+webp/About/about_5x7+(43).webp",
//     "about_image_color": "Nil",
//     "shop_image": "5x7+webp/Shop/shop_5x7+(5).webp",
//     "shop_image_color": "Red"
// },
// {
//     "about_id": 118,
//     "shop_id": 146,
//     "about_image": "about_5x7 (415).webp",
//     "about_image_color": "Blue",
//     "shop_image": "5x7+webp/Shop/shop_5x7+(9).webp",
//     "shop_image_color": "Red"
// },
// ]
const DekstopHomePageTest = () => {
    const [imageData, setImageData] = useState([]);
    useEffect(() => {
        const preloadImage = (src) => {
            const img = new Image();
            img.src = src;
        };

        const fetchImages = async () => {
            try {
                const response = await axios.get('/contents/getImageLessContent');
                const data = response.data.data;
        
                let adjustedData = [];
                let lastAboutColor = null;
                let lastShopColor = null;
        
                const shuffleArray = (arr) => {
                    for (let i = arr.length - 1; i > 0; i--) {
                        const j = Math.floor(Math.random() * (i + 1));
                        [arr[i], arr[j]] = [arr[j], arr[i]];
                    }
                    return arr;
                };
        
                let shuffledData = shuffleArray([...data]);
        
                const findNextValidImage = (remainingData, lastAbout, lastShop) => {
                    return remainingData.find(
                        (image) =>
                            image.about_image_color !== lastAbout &&
                            image.shop_image_color !== lastShop
                    );
                };
        
                while (shuffledData.length > 0) {
                    let nextImage = findNextValidImage(shuffledData, lastAboutColor, lastShopColor);
        
                    if (!nextImage) {
                        console.warn('No valid image found. Resetting constraints for remaining images.');
                        shuffledData = shuffleArray(shuffledData);
                        nextImage = shuffledData[0]; 
                    }
        
                    adjustedData.push(nextImage);
        
                    preloadImage(`${BASE_URL}${nextImage.about_image}`);
                    preloadImage(`${BASE_URL}${nextImage.shop_image}`);
        
                    lastAboutColor = nextImage.about_image_color;
                    lastShopColor = nextImage.shop_image_color;
        
                    shuffledData = shuffledData.filter((img) => img !== nextImage);
                }
        
                setImageData(adjustedData);
                console.log('Adjusted image data with enforced variety:', adjustedData);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };
        

        fetchImages();
    }, []);


    return (

        <>
            {imageData && imageData.length > 0 && (
                <>
                    <AppBar
                        position="absolute"
                        sx={{
                            color: '#000',
                            boxShadow: 'none',
                            top: 0,
                            left: 0,
                            right: 0,
                            background: 'transparent',
                        }}
                    >
                        <Toolbar
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingX: { xs: 1, sm: 2, md: 4 },
                            }}
                        >
                            <a href='https://shop.alamardesigns.com/about' style={{ textDecoration: 'none', }}>
                                <span
                                    style={{
                                        cursor: 'pointer',
                                        color: 'white',
                                        letterSpacing: '2px',
                                        fontSize: 'clamp(12px, 1.5vw, 18px)',
                                    }}
                                >
                                    ABOUT
                                </span>
                            </a>
                            <Box
                                component="img"
                                src={logo}
                                alt="Alamar Logo"
                                sx={{
                                    width: { xs: '80px', sm: '100px', md: '140px' },
                                    height: { xs: '40px', sm: '50px', md: '70px' },
                                    marginLeft: { xs: '-10px', sm: '-15px' },
                                }}
                            />
                            <a href='https://shop.alamardesigns.com/shop' style={{ textDecoration: 'none', }}>
                                <span
                                    style={{
                                        cursor: 'pointer',
                                        color: 'white',
                                        letterSpacing: '2px',
                                        fontSize: 'clamp(12px, 1.5vw, 18px)',
                                    }}
                                >
                                    SHOP
                                </span>
                            </a>
                        </Toolbar>
                    </AppBar>

                    <div className="page-container" style={{ height: '100vh', width: '100vw' }}>
                        <div className="image-row" style={{ height: '100%', display: 'flex', width: '100vw' }}>
                            <div className="left-image" style={{ flex: 1, height: '100%' }}>
                                <Swiper
                                    direction={'vertical'}
                                    loop={true}
                                    autoplay={{
                                        delay: 7000,
                                        disableOnInteraction: false,
                                    }}
                                    speed={5000}
                                    modules={[Autoplay]}
                                    style={{ width: '100%', height: '100%' }}
                                    className="mySwiper"
                                >
                                    {imageData.map((image, index) => (
                                        <SwiperSlide key={index} style={{ height: '100%' }}>
                                            <div className="image" style={{ height: '100%' }}>
                                                <a href='https://shop.alamardesigns.com/about' style={{ textDecoration: 'none' }}>
                                                    <img
                                                        src={`${BASE_URL}${image.about_image}`}
                                                        loading="lazy"
                                                        alt={`Left view ${index + 1}`}
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
                                                    // onClick={() => window.open('https://shop.alamardesigns.com/about')}
                                                    />
                                                </a>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>

                            <div className="right-image" style={{ flex: 1, height: '100%' }}>
                                <Swiper
                                    direction={'vertical'}
                                    loop={true}
                                    autoplay={{
                                        delay: 7000,
                                        disableOnInteraction: false,
                                    }}
                                    speed={5000}
                                    modules={[Autoplay]}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        transform: 'rotate(180deg)',
                                    }}
                                    className="mySwiper"
                                >
                                    {imageData.map((image, index) => (
                                        <SwiperSlide key={index} style={{ height: '100%' }}>
                                            <div
                                                className="image"
                                                style={{
                                                    height: '100%',
                                                    transform: 'rotate(180deg)',
                                                }}
                                            >
                                                <a href='https://shop.alamardesigns.com/shop' style={{ textDecoration: 'none' }}>
                                                    <img
                                                        src={`${BASE_URL}${image.shop_image}`}
                                                        loading="lazy"
                                                        alt={`Right view ${index + 1}`}
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
                                                    />
                                                </a>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>

                    </div>
                </>
            )
                // : (
                //     <div style={{
                //         display: 'flex',
                //         justifyContent: 'center',
                //         alignItems: 'center',
                //         height: '100vh',
                //         width: '100vw',
                //     }}>
                //          <img
                //             src={heartlogo}
                //             alt="alamar"
                //             style={{
                //                 objectFit: 'contain',
                //                 maxWidth: '25%',
                //                 maxHeight: '20%',
                //                 animation: 'bounce 0.5s infinite ease-in-out',
                //             }}
                //         /> 
                //     </div>
                // )
            }
        </>
    );
};

export default DekstopHomePageTest;