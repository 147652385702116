import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import MobileHomePage from './component/mobileHomePage';
import DekstopHomePageMain from './component/desktopHomePage-main';
import DekstopHomePageTest from './component/desktopHomePageTest';
import MobileHomePageTest from './component/mobileHomePageTest';
import MobileHomePageTesting from './component/mobileHomePageTesting';



const App = () => {
  const [deviceType, setDeviceType] = useState('mobile');

  useEffect(() => {
    const updateDeviceType = () => {
      if (window.innerWidth <= 768) {
        setDeviceType('mobile');
      } else {
        setDeviceType('desktop');
      }
    };

    updateDeviceType();

    window.addEventListener('resize', updateDeviceType);

    return () => window.removeEventListener('resize', updateDeviceType);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={deviceType === "mobile" ? <MobileHomePageTest /> : <DekstopHomePageTest />} />
        <Route path="/newtest" element={deviceType === "mobile" ? <MobileHomePageTesting /> : <DekstopHomePageTest />} />
        <Route path="/testing" element={deviceType === "mobile" ? < MobileHomePage /> : < DekstopHomePageMain />} />
        {/* <Route path="/" element={deviceType === "mobile" ? <MobileHomePage /> : <DekstopHomePageMain />} />
        <Route path="/testing" element={deviceType === "mobile" ? <MobileHomePageTest /> : <DekstopHomePageTest />} /> */}
      </Routes>
    </Router>



  );
};

export default App;
