import React from "react";
import { Box } from "@mui/material";
import logo from '../assets/logo/alamar.png';
// import axios from 'axios';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import about_latest01 from '../assets/image/9x16-1.webp';
import shop_latest01 from '../assets/image/9x16-2.webp';
import about_latest02 from '../assets/image/9x16-3.webp';
import shop_latest02 from '../assets/image/9x16-4.webp';

import about_latest03 from '../assets/image/9x16-5.webp';
import shop_latest03 from '../assets/image/9x16-6.webp';
import about_latest04 from '../assets/image/9x16-7.webp';
import shop_latest04 from '../assets/image/9x16-8.webp';

import about_latest05 from '../assets/image/9x16-9.webp';
import shop_latest05 from '../assets/image/9x16-10.webp';
import about_latest06 from '../assets/image/9x16-11.webp';
import shop_latest06 from '../assets/image/9x16-12.webp';

import about_latest07 from '../assets/image/9x16-13.webp';
import shop_latest07 from '../assets/image/9x16-14.webp';
import about_latest08 from '../assets/image/9x16-15.webp';
import shop_latest08 from '../assets/image/9x16-16.webp';

import about_latest09 from '../assets/image/9x16-17.webp';
import shop_latest09 from '../assets/image/9x16-18.webp';
import about_latest010 from '../assets/image/9x16-21.webp';
import shop_latest010 from '../assets/image/9x16-20.webp';

import about_latest011 from '../assets/image/9x16-23.webp';
import shop_latest011 from '../assets/image/9x16-22.webp';
import about_latest012 from '../assets/image/9x16-25.webp';
import shop_latest012 from '../assets/image/9x16-24.webp';

import about_latest013 from '../assets/image/9x16-27.webp';
import shop_latest013 from '../assets/image/9x16-26.webp';
import about_latest014 from '../assets/image/9x16-29.webp';
import shop_latest014 from '../assets/image/9x16-28.webp';

import about_latest015 from '../assets/image/9x16-31.webp';
import shop_latest015 from '../assets/image/9x16-30.webp';
import about_latest016 from '../assets/image/9x16-33.webp';
import shop_latest016 from '../assets/image/9x16-32.webp';

import about_latest017 from '../assets/image/9x16-35.webp';
import shop_latest017 from '../assets/image/9x16-34.webp';
import about_latest018 from '../assets/image/9x16-37.webp';
import shop_latest018 from '../assets/image/9x16-36.webp';

import about_latest019 from '../assets/image/9x16-39.webp';
import shop_latest020 from '../assets/image/9x16-38.webp';
import about_latest021 from '../assets/image/9x16-41.webp';
import shop_latest021 from '../assets/image/9x16-40.webp';

// const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://alamar-home.s3.eu-west-2.amazonaws.com/';

const imagesData = [
    about_latest01, shop_latest01,
    about_latest02, shop_latest02,
    about_latest03, shop_latest03,
    about_latest04, shop_latest04,
    about_latest05, shop_latest05,
    about_latest06, shop_latest06,
    about_latest07, shop_latest07,
    about_latest08, shop_latest08,
    about_latest09, shop_latest09,
    about_latest010, shop_latest010,
    about_latest011, shop_latest011,
    about_latest012, shop_latest012,
    about_latest013, shop_latest013,
    about_latest014, shop_latest014,
    about_latest015, shop_latest015,
    about_latest016, shop_latest016,
    about_latest017, shop_latest017,
    about_latest018, shop_latest018,
    about_latest019, shop_latest020,
    about_latest021, shop_latest021,
];


const rotateArray = (arr) => {
    const randomStartIndex = Math.floor(Math.random() * arr.length);
    return [...arr.slice(randomStartIndex), ...arr.slice(0, randomStartIndex)];
};

const MobileHomePageTesting = () => {
    const rotatedImages = rotateArray(imagesData);
console.log(rotatedImages);

    return (
        <Box
            className="homepage-container"
            sx={{
                position: "relative",
                width: "100vw",
                minHeight: "100svh",
                height: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#000",
                overflow: "hidden",
                padding: "env(safe-area-inset)",
            }}
        >
            <Box
                component="img"
                src={logo}
                alt="Logo"
                sx={{
                    position: "absolute",
                    top: "8px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 2,
                    width: "100px",
                }}
            />

            <a href='https://shop.alamardesigns.com/about/' style={{ textDecoration: 'none', }}>
                <Box
                    sx={{
                        position: "absolute",
                        bottom: "20px",
                        left: "20px",
                        color: "#fff",
                        zIndex: 3,
                        cursor: "pointer",
                        letterSpacing: "0.3em",
                        display: "flex",
                    }}
                >
                    <span style={{ marginRight: "4px" }}>ABOUT</span>
                </Box>
            </a>

            <a href='https://shop.alamardesigns.com/shop/' style={{ textDecoration: 'none', }}>
                <Box
                    sx={{
                        position: "absolute",
                        bottom: "20px",
                        right: "20px",
                        color: "#fff",
                        zIndex: 3,
                        cursor: "pointer",
                        letterSpacing: "0.3em",
                        display: "flex",
                    }}
                >
                    <span style={{ marginRight: "4px" }}>SHOP</span>
                </Box>

            </a>


            <div className="containers">
                <Slide
                    easing="linear"
                    duration={7000}
                    transitionDuration={100}
                    autoplay={true} 
                    arrows={false} 
                    pauseOnHover={false}
                    canSwipe={true} >
                    {rotatedImages.map((slide, index) => (
                        <div className="slide" key={index}>
                            <div style={{ backgroundImage: `url(${slide})` }}></div>
                        </div>
                    ))}
                </Slide>
            </div>
        </Box>
    );
};

export default MobileHomePageTesting;
